import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
// images
import LineFull from '../images/linefull.jpg';
import SEO from '../components/seo';
import '../styles/app.scss';

const SchoolsOut = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />

            <section className="page-section smallestwdt centersec">
                <div className="container">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: post.content,
                        }}
                    />
                    <a
                        href="https://clients.mindbodyonline.com/classic/home?studioid=44354"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="wrtsbtn yellowbtn inlinebtn"
                    >
                        {/*
                        href={`tel:${post.acf.schools_out_call_number}`}
                        CALL {post.acf.schools_out_call_number} TO LEARN MORE
                        */}
                        Click here to sign up
                    </a>
                    <img
                        className="schoolsline"
                        src={LineFull}
                        alt="linefull"
                    />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: post.so_sessions_text,
                        }}
                    />
                    <img
                        src={
                            post.acf.so_program_image.localFile.childImageSharp
                                .fluid.src
                        }
                        alt="schools program"
                    />
                    <img
                        className="schoolsline"
                        src={LineFull}
                        alt="linefull"
                    />
                    <h2 className="bluetxt">Check our pricing</h2>
                    <div className="smallestwdt packagesdiv">
                        {post.acf.so_sessions.map((session, i) => (
                            <div
                                className="pricingbox schoolspricing bg-primary text-white"
                                key={i}
                            >
                                <h3>
                                    <span
                                        className="yellowtxt"
                                        dangerouslySetInnerHTML={{
                                            __html: session.session_title,
                                        }}
                                    />
                                    <br />

                                    {session.session_pricing}
                                </h3>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            <section className="whywelist page-section bg-secondary text-white">
                <div className="container flexwrap bdaytxtadjust">
                    <div className="whylistlft flexbox">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.so_enjoy_title,
                            }}
                        />
                        <p
                            className="biggertxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.so_enjoy_content,
                            }}
                        />

                        <h2 className="yellowtxt">
                            CALL {post.acf.schools_out_call_number} TO LEARN
                            MORE
                        </h2>
                    </div>

                    <div className="flexbox">
                        <div className="equipstarimgrgt">
                            <img
                                className="whiteborderimg"
                                src={
                                    post.acf.so_enjoy_image.localFile
                                        .childImageSharp.fluid.src
                                }
                                alt="unlimitedOp"
                            />
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default SchoolsOut;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            content
            yoast_meta {
                name
                property
            }
            yoast_title
            acf {
                schools_out_call_number
                so_sessions_text
                so_enjoy_title
                so_enjoy_content
                so_sessions {
                    session_title
                    session_pricing
                }
                so_program_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                so_enjoy_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
            }
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
